@tailwind base;

.btnHead {
	@apply bg-gray-800;
	@apply text-white;
	@apply p-2; 
	@apply rounded;
}

.btnHead:hover {
	@apply bg-gray-700;
}

.btnHead:focus {
	@apply outline-none;
}

.tableDefault {
	@apply w-11/12;
	@apply mx-auto;
	@apply mt-3;
}

.tableDefault th {
	@apply bg-gray-200;
	@apply text-left;
	@apply px-2;
	@apply py-1;
	@apply border-b;
	@apply border-gray-300
}
.tableDefault td {
	@apply text-left;
	@apply px-2;
	@apply py-1;
	@apply border-b;
	@apply border-gray-100
}

.tableDefault .loading {
	@apply text-center;
	@apply py-2;
}

.tableDefault .norecords {
	@apply text-left;
	@apply p-2;
}

.tableDefault tr:hover {
	@apply bg-blue-50;
}

.btnEdit {
	@apply text-yellow-500;
	@apply mr-2;
}

.btnEdit:hover {
	@apply text-yellow-800;
}

.btnDelete {
	@apply text-red-500;
	@apply mr-2;
}

.btnDelete:hover {
	@apply text-red-800;
}

.ulMenuRecipe a {
	@apply block;
	@apply p-3;
	@apply border-b;
	@apply border-gray-200;
	@apply text-gray-400;
}
.ulMenuRecipe a:hover {
	@apply text-gray-600;
	@apply bg-gray-50;
}
.ulMenuRecipe i {
	@apply  mr-2;
}

.ulMenuRecipe .active {
	@apply text-black;
	@apply font-bold;
	@apply border-l-8;
	@apply border-blue-400
}

.recipe_title {
	@apply font-bold;
	@apply w-11/12;
	@apply mx-auto;
	@apply bg-black;
	@apply text-white;
	@apply rounded;
	@apply ml-10;
	@apply px-10;
	@apply py-2;
	@apply shadow;
}

@tailwind components;
@tailwind utilities;